const createHero = function () {
  const scene = document.createElement('div');
  scene.setAttribute('id', 'js-scene');
  scene.innerHTML = `
    <div class="bg-lt">
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-pink.png"
        alt=""
        data-depth="0.2"
      />
      <img
        class="rellax"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-blue.png"
        alt=""
        data-depth="0.5"
        data-rellax-speed="-3"
        data-rellax-percentage="0.5"
      />
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-blue2.png"
        alt=""
        data-depth="0.2"
      />
     
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-yellow.png"
        alt=""
        data-depth="0.35"
      />
    </div>
    
    <div class="bg-rb">
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-pink.png"
        alt=""
        data-depth="0.2"
      />
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-yellow.png"
        alt=""
        data-depth="0.25"
      />
      <img
        class="rellax"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-blue.png"
        alt=""
        data-rellax-speed="-3"
        data-rellax-percentage="0.5"
      />
     
      <img
        class="layer"
        src="https://samochod-roku-2024.wpcdn.pl/img/bg/g-blue.png"
        alt=""
        data-depth="0.25"
        data-rellax-speed="-2.4"
        data-rellax-percentage="0.5"
      />
    </div>
    `;
  const btnSlide = document.createElement('button');
  btnSlide.classList.add('slide--down');
  btnSlide.setAttribute('data-slug', 'kategorie');
  document.getElementById('strona-glowna').prepend(scene);
  document.getElementById('strona-glowna').append(btnSlide);
};

if (document.getElementById('strona-glowna')) {
  createHero();
  const scene = document.getElementById('js-scene');
  const parallax = new Parallax(scene);
  const rellax = new Rellax('.rellax');
}
