(function () {
  const span = document.createElement('span');
  const btn = document.querySelector('.header__hamburger');
  const nav = document.querySelector('.header__nav');
  btn.append(span);
  const child = btn.childNodes[1].classList;

  btn.addEventListener('click', function () {
    // btn.classList.toggle('__js_isActive');
    nav.classList.toggle('__js_isActive');

    if (child.contains('hamburger--to-arrow')) {
      child.remove('hamburger--to-arrow');
      child.add('hamburger--from-arrow');
    } else {
      child.remove('hamburger--from-arrow');
      child.add('hamburger--to-arrow');
    }
  });
  nav.querySelector('ul').addEventListener('click', function () {
    // btn.classList.remove('__js_isActive');
    if (child.contains('hamburger--to-arrow')) {
      child.remove('hamburger--to-arrow');
      child.add('hamburger--from-arrow');
    } else {
      child.remove('hamburger--from-arrow');
      child.add('hamburger--to-arrow');
    }
    setTimeout(function () {
      nav.classList.remove('__js_isActive');
    }, 500);
  });
})();
