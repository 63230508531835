let teaserIdx = 0;

if (document.querySelector('.form--close')) {
  document.querySelector('.form--close').addEventListener('click', function () {
    let animationTime = 0;
    if (document.querySelectorAll('.teaser').length > 0) {
      animationTime = 300;
      window.scrollTo({
        top:
          window.pageYOffset +
          document
            .querySelectorAll('.teaser')
            [teaserIdx].getBoundingClientRect().top -
          100,
        left: 0,
        behavior: 'smooth',
      });
    }
    setTimeout(function () {
      document
        .querySelector('.article__form')
        .classList.remove('form--visible');
    }, animationTime);
  });
}

document.querySelectorAll('.agrees').forEach(function (el) {
  const span = document.createElement('span');
  el.appendChild(span);
});

const setFormVisibility = (row, src, text) => {
  if (document.querySelector('.article__form')) {
    const formWrapper = document.querySelector('.article__form');
    formWrapper.style.setProperty('--form-grid', row);
    if (formWrapper.querySelector('.form__selection')) {
      formWrapper.querySelector('.form__selection').innerHTML = `
     <img src="${src}"
          alt="${text}"/>
     <h4>${text}</h4>`;
    }
    setTimeout(function () {
      formWrapper.classList.add('form--visible');
      window.scrollTo({
        top: window.pageYOffset + formWrapper.getBoundingClientRect().top - 100,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }
};

if (document.querySelectorAll('.btn--vote').length > 0) {
  document.querySelectorAll('.btn--vote').forEach(function (btn) {
    btn.addEventListener('click', function () {
      const itemIdx = getIndex(this.closest('.teaser')) + 1;
      teaserIdx = getIndex(this.closest('.teaser'));
      const computedRow =
        Math.ceil(
          itemIdx /
            getComputedStyle(document.documentElement).getPropertyValue(
              '--grid'
            )
        ) + 1;
      const src = this.closest('.teaser')
        .querySelector('img')
        .getAttribute('src');
      const text = this.closest('.teaser').querySelector('h4').innerText;
      setFormVisibility(computedRow, src, text);
    });
  });
}
