if (document.querySelectorAll('.teaser__text').length > 0) {
  document.querySelectorAll('.teaser__text').forEach((t) => {
    for (let i = 0; i < 4; i++) {
      const span = document.createElement('span');
      span.classList.add('border');
      t.append(span);
    }
  });
}
const setSize = function () {
  if (document.querySelector('.section__wrapper#strona-glowna')) {
    document
      .querySelector('.section__wrapper#strona-glowna')
      .style.setProperty('--intro-height', window.innerHeight + 'px');
  }
  // if (document.querySelector('.article--ajax-wrapper')) {
  //   document
  //     .querySelectorAll('.article--ajax-wrapper')[0]
  //     .querySelectorAll('.article__heading')[0]
  //     .style.setProperty(
  //       '--mask-height',
  //       Math.ceil(
  //         document
  //           .querySelectorAll('.article--ajax-wrapper')[0]
  //           .querySelectorAll('.article__heading')[0]
  //           .getBoundingClientRect().height
  //       ) +
  //         100 +
  //         'px'
  //     );
  // }
};

window.addEventListener('DOMContentLoaded', () => {
  setSize();
});
window.addEventListener('resize', (event) => {
  setSize();
});
