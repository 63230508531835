function rwdSrc() {
  document.querySelectorAll('[data-src-desktop]').forEach((el) => {
    const src =
      window.innerWidth > 640
        ? el.getAttribute('data-src-desktop')
        : el.getAttribute('data-src-mobile');
    el.setAttribute('src', src);
  });
}

rwdSrc();

window.addEventListener('resize', debounce(rwdSrc, 250));
