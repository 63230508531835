const handleFormVoting = function () {
  const data = {};

  if (document.querySelectorAll('.form__wrapper').length > 0) {
    const form = document.querySelector('.form__wrapper');
    const fieldToValidate = form.querySelectorAll('.form-field');
    const fieldsNotMail = form.querySelectorAll(
      '.form-field:not([data-id="email"])'
    );
    const mailToValidate = form.querySelector('.form-field[data-id="email"]');
    const checkboxes = form.querySelectorAll('.form-checkbox');

    const validateInput = function (input) {
      input.addEventListener('input', function () {
        if (input.closest('fieldset').classList.contains('has-error')) {
          validateField(this, 2);
        }
      });
      input.addEventListener('change', function () {
        validateField(this, 2);
      });
    };

    const validateMail = function (mail) {
      mail.addEventListener('input', function () {
        if (mail.closest('fieldset').classList.contains('has-error')) {
          validateEmail(this);
        }
      });
      mail.addEventListener('change', function () {
        validateEmail(this);
      });
    };

    fieldsNotMail.forEach(function (el) {
      validateInput(el);
    });
    validateMail(mailToValidate);

    checkboxes.forEach((el) => {
      el.addEventListener('change', function () {
        validateCheckbox(this);
      });
    });

    const submitBtns = form.querySelectorAll('.send-vote');

    // oddanie glosu - wysylanie danych z formularza
    submitBtns.forEach((btn) => {
      btn.addEventListener('click', function (e) {
        e.preventDefault();
        let formIsValid = true;
        data.selectedCar = form.querySelector('.form__selection h4').innerText;

        // walidacja formularza z danymi

        if (btn.classList.contains('send-data')) {
          fieldsNotMail.forEach((el) => {
            if (!validateField(el, 2)) {
              formIsValid = false;
            } else {
              data[el.getAttribute('data-id')] = el.value;
            }
          });
          if (!validateEmail(mailToValidate)) {
            formIsValid = false;
          } else {
            data[mailToValidate.getAttribute('data-id')] = mailToValidate.value;
          }

          checkboxes.forEach((el) => {
            if (!validateCheckbox(el)) {
              formIsValid = false;
            } else {
              data[el.getAttribute('data-id')] = el
                .closest('fieldset')
                .querySelector('label').innerText;
            }
          });
          data.button = 'vote_register';
        } else {
          // tutaj kod bez danych
          fieldToValidate.forEach((el) => {
            // jak cos ma error z fieldstovalidate to usun error
            el.closest('fieldset').classList.remove('has-error');
            el.closest('fieldset').setAttribute('data-error', '');
            if (el.value.trim().length > 0) {
              data[el.getAttribute('data-id')] = el.value;
            }
          });
          checkboxes.forEach((el) => {
            el.nextElementSibling.classList.remove('has-error');
            el.classList.remove('has-error');
            if (el.checked) {
              data[el.getAttribute('data-id')] = el
                .closest('fieldset')
                .querySelector('label').innerText;
            }
          });
          data.button = 'vote';
        }

        if (formIsValid && data.selectedCar) {
          createSpinner(form);

          /* test */
          // console.log(data);
          // setTimeout(function () {
          //   const vC = form.closest('.article__form');
          //   const response = `  <div class="article__text">
          //   <div class="form__completed">
          //     <h4>Dziękujemy za oddany głos</h4>
          //     <p>wyniki plebiscytu ogłosimy niebawem</p>
          //   </div>
          // </div>`;
          //   vC.innerHTML = response;
          //   window.scrollTo({
          //     top: window.pageYOffset + vC.getBoundingClientRect().top - 170,
          //     left: 0,
          //     behavior: 'smooth',
          //   });
          //   if (document.querySelectorAll('.btn--vote').length > 0) {
          //     document.querySelectorAll('.btn--vote').forEach(function (btn) {
          //       btn.setAttribute('disabled', 'true');
          //     });
          //   }
          // }, 2000);
          /* koniec testu */

          /* FORMULARZ */
          const url = form.querySelector('form').getAttribute('data-url');
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.text())
            .then((html) => {
              const vC = form.closest('.article__form');
              vC.innerHTML = html;
              window.scrollTo({
                top: window.pageYOffset + vC.getBoundingClientRect().top - 170,
                left: 0,
                behavior: 'smooth',
              });
              if (document.querySelectorAll('.btn--vote').length > 0) {
                document.querySelectorAll('.btn--vote').forEach(function (btn) {
                  btn.setAttribute('disabled', 'true');
                });
              }
            })
            .catch((err) => {
              // console.log('Error', err);
            });
          /* KONIEC FORMULARZA */
        }
      });
    });
  }
};

handleFormVoting();
