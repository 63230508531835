const animatedElements = document.querySelectorAll(
  '._js_inViewport, .__js_preload'
);

const obsCallback = function (entries, observer) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('_js_inViewport')) {
        entry.target.classList.add('__js_onScreen');
      }
      if (entry.target.classList.contains('__js_preload')) {
        const srcS = entry.target.getAttribute('data-src');
        $(entry.target)
          .css('opacity', '.3')
          .attr('src', srcS)
          .animate({ opacity: 1 }, 250);
        entry.target.classList.remove('__js_preload');
        observer.unobserve(entry.target);
      }
    } else {
      if (
        entry.target.classList.contains('_js_inViewport') &&
        entry.target.classList.contains('__js_onScreen')
      ) {
        // entry.target.classList.remove('__js_onScreen');
        observer.unobserve(entry.target);
      }
    }
  });
};

const observer = new IntersectionObserver(obsCallback, {
  root: null,
  threshold: 0.1,
  // rootMargin: '-10px'
});

animatedElements.forEach((elem) => {
  observer.observe(elem);
});
